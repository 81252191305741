import React, { useState, useEffect } from "react";
import SubTitle from "Theme/SubTitle";
import axios from "axios";
import Spinner from "Theme/Spinner";
import LinkButton from "Theme/LinkButton";
import Button from "Theme/Button";

const isNumeric = (value) => /^-?\d+$/.test(value);

const AutoDetector = ({ callback, setSn }) => {
  const [loading, setLoading] = useState(true);
  const [computer, setComputer] = useState();
  const [appInstalled, setAppInstalled] = useState(false);

  const submit = (event) => {
    event.preventDefault();

    setSn(computer.sn);
    callback(computer.sn);
  };

  useEffect(() => {
    axios
      .get("http://localhost:5055/")
      .then((result) => {
        setAppInstalled(true);
        setComputer(result.data);
        setLoading(false);
        setSn(result.data.sn);
        callback(result.data.sn);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setAppInstalled(false);
        }
        setLoading(false);
      });
  }, []);

  return (
    <>
      <SubTitle>Automatyczna identyfikacja komputera</SubTitle>
      {loading === true ? (
        <Spinner size={12} />
      ) : (
        <>
          {appInstalled && computer ? (
            <>
              <p>Model: {computer.model}</p>
              <p className="pb-4">
                Numer seryjny: {isNumeric(computer.sn) ? computer.sn : "brak"}
              </p>
              {isNumeric(computer.sn) ? (
                <Button onClick={submit} text="Wybierz wykryty system" />
              ) : null}
            </>
          ) : (
            <>
              <p className="pb-4">Nie wykryto aplikacji NTT Support App</p>
              <LinkButton title="Pobierz" href="/supportapp-realsn.zip" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default AutoDetector;

import SubTitle from "Theme/SubTitle";
import Input from "Theme/Input";
import {useState, useEffect } from "react";
import axios from "axios";


const Thanks = ({errorDetails, status}) => {

  return (
      <>
      {status && (
      <div className="bg-red-100 border-t-4 border-red-500 mt-6 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
      <div className="flex">
          <div className="py-1">
              <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
              </svg>
          </div>
          <div>
              <p className="font-bold">Błąd...</p>
              <p className="text-sm">Spróbuj ponownie za kilka chwil lub skontaktuj się z nami telefonicznie</p>
          </div>
      </div>
  </div>  
      )}


      {!status && errorDetails !== "" && (
              <div className="bg-red-100 border-t-4 border-red-500 mt-6 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
              <div className="flex">
                  <div className="py-1">
                      <svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                      </svg>
                  </div>
                  <div>
                      <p className="font-bold">Błąd zgłoszenia...</p>
                      <p className="text-sm">{errorDetails}</p>
                  </div>
              </div>
          </div>  
      )}


      {!status && (
      <div className="bg-sky-100 border-t-4 border-sky-500 mt-6 rounded-b text-sky-900 px-4 py-3 shadow-md" role="alert">
      <div className="flex">
          <div className="py-1">
              <svg className="fill-current h-6 w-6 text-sky-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
              </svg>
          </div>
          <div>
              <p className="font-bold">Zgłoszenie zostało wysłane</p>
              <p className="text-sm">Poinformujemy Cię o szczegółach za pośrednictwem wiadomości email.</p>
          </div>
      </div>
  </div>
      )}

      </>
  )
}


const serviceForm = {
  serialnumber: "",
  symkar: "",
  computername: "",
  name:"",
  contactperson:"",
  phone:"",
  email:"",
  street:"",
  postcode:"",
  city:"",
  description:""
}


const OrderParts = ({computer}) => {

  const [inputs, setInputs] = useState(serviceForm);
  const [showThanks, setThanks] = useState(false);
  const [failedSend, setFailedSend] = useState(true);
  const [numerZlecenia, setNumerZlecenia] = useState("")
  const [errorDetails, setErrorDetails] = useState("");
  
  useEffect(()=> {
    setInputs(values => ({...values, serialnumber:computer.sn}));
    setInputs(values => ({...values, computername:computer.name}));
    setInputs(values => ({...values, symkar:computer.symkar}));
  }, [computer])


  const sendToService = () => {
    axios
      .post(`/proxy.php`, {
        method: "send-part",
        data: inputs
       
      })
      .then(({ data }) => {
        if (data.data.status==="ERROR") {
          setErrorDetails(data.data.error)
          setThanks(true);
          setFailedSend(false);
        } else {
          setThanks(true);
          setFailedSend(false);
          setNumerZlecenia(data.data.numerZlecenia);
        }

      })
      .catch((error) => {
        console.error(error);
        setFailedSend(false);
      });
  };

  const handleSendToService = (event) => {
    event.preventDefault();
    
    //tymczasowo pomijam walidacje
    //sprawdzamy czy nie jest pusty
    sendToService();
    return;
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  return (
        <>
          <div>
            <SubTitle>
                Zamówienie części zamiennych: 
            </SubTitle>

          </div>
        {failedSend && (
          <>
          <div>
          <form className="w-full max-w-lg" onSubmit={handleSendToService}>
          <div>
            <p>Numer seryjny: {computer.sn}</p><p>Model: {computer.name}</p>
          </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <Input type="text" name="name" id="name" value={inputs.name} onChange={handleChange} text="Nazwa zgłaszającego"/>
                <Input type="text" name="contactperson" id="contactperson" value={inputs.contactperson} onChange={handleChange} text="Osoba kontaktowa"/>
                <Input type="text" name="email" id="email" value={inputs.email} onChange={handleChange} text="Adres email"/>
                <Input type="text" name="phone" id="phone" value={inputs.phone} onChange={handleChange} text="Numer telefonu"/>
                <Input type="text" name="street" id="street" value={inputs.street} onChange={handleChange} text="Ulica"/>
                <Input type="text" name="postcode" id="postcode" value={inputs.postcode} onChange={handleChange} text="Kod pocztowy"/>
                <Input type="text" name="city" id="city" value={inputs.city} onChange={handleChange} text="Miejscowość"/>
                <Input type="text" name="description" id="description" value={inputs.description} onChange={handleChange} text="Nazwa komponentu"/>
            </div>
            <Input type="submit" value="Wyślij zgłoszenie" />
        </form>
          </div>
                  <div>
                  
                </div>
                </>
          )}

        <div>
          {showThanks && (
            <Thanks errorDetails={errorDetails} numerZlecenia={numerZlecenia} sn={computer.sn} model={computer.name} status={failedSend} />
          )}
        </div>
        </>

  );
};

export default OrderParts;

import { Link } from "react-router-dom";
import Title from "Theme/Title";
import Container from "Theme/Container";
import WhiteBox from "Theme/WhiteBox";
import AutoDetector from "Components/AutoDetector";
import SnFinder from "Components/SnFinder";
import { useState } from "react";
import Warranty from "Components/Warranty";
import Drivers from "Components/Drivers";
import axios from "axios";
import DiskImage from "Components/DiskImage";
import ReportToService from "Components/ReportToService";
import OrderParts from "Components/OrderParts/OrderParts";

function HomePage () {
  const [sn, setSn] = useState("");
  const [error, setError] = useState(false);
  const [computer, setComputer] = useState(null);
  const [reportToSupport, setReport] = useState(false);
  const [orderParts, setOrderParts] = useState(false);

  const search = (sn) => {
   
    axios
      .get(`https://support.ntt.pl/proxy.php?sn=${sn}`)
      .then(({ data }) => {
        if(data.data) {
          setReport(false);
          setComputer(data.data);
          setError(false);
        } else {
          setError(true);
        }
        
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };
  return (
      <>
      <Container>
        <div className="columns-1">
          <div>
            <Title>
              Witamy na stronie pomocy technicznej firmy NTT System S.A.
            </Title>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          <WhiteBox>
            <SnFinder callback={search} sn={sn} setSn={setSn} error={error} />
          </WhiteBox>
          <WhiteBox>
            <AutoDetector callback={search}  setSn={setSn} />
          </WhiteBox>
        </div>
      </Container>

      {computer && (
        <>
          <Container>
            <Warranty computer={computer} reportToSupport={reportToSupport} setReport={setReport} setOrderParts={setOrderParts}/>
          </Container>

          {reportToSupport && (
          <Container>
            <ReportToService computer={computer}></ReportToService>
          </Container>
          )}

          {orderParts && (
            <Container>
              <OrderParts computer={computer} />
            </Container>
          )}


          <Container>
            <Drivers computer={computer} />
          </Container>

          {computer.images && (
            <Container>
              <DiskImage computer={computer} />
            </Container>
          )}
        </>
      )}
      </>
  );
}

export default HomePage;
